import Navigation from "./components/Navigation";
import Portfolios from "./components/Portfolios";

function App() {
  return (
    <div className="app">
      <Navigation />
      <Portfolios />
    </div>
  );
}

export default App;
