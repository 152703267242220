import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import tasksLogo from "../images/tasks.webp";
import landingLogo from "../images/landing.png";

const portfolios = [
  { name: "Home", image: tasksLogo },
  { name: "Portfolios", image: landingLogo },
];

export default function Portfolios() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="relative max-w-5xl mx-auto ">
      {portfolios.map((portfolio) => {
        return (
          <figure
            style={{ background: "#fffcf9" }}
            className="shadow-lg md:flex mb-20 ml-4 mr-4 rounded-xl p-8 md:p-0 "
          >
            <img
              className="w-24 h-24 md:w-96 md:h-96 md:rounded-none rounded-full mx-auto"
              src={portfolio.image}
              alt=""
              width="384"
              height="512"
            />
            <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
              <blockquote>
                <p className="text-lg font-medium">
                  “Tailwind CSS is the only framework that I've seen scale on
                  large teams. It’s easy to customize, adapts to any design, and
                  the build size is tiny.”
                </p>
              </blockquote>
              <figcaption className="font-medium">
                <div className="text-sky-500 dark:text-sky-400 flex flex-row justify-end">
                  <a href="htpps://www.clevers.io">View</a>
                </div>
              </figcaption>
            </div>
          </figure>
        );
      })}
    </div>
  );
}
